import React from 'react';
import { FaYoutube, FaFacebookSquare, FaInstagram, FaPhoneAlt, FaLink } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <footer className="foot py-3">
                <div className="container text-center border-bottom border-white">
                    <h1 className='text-white fw-bold mb-2' data-aos="zoom-in" data-aos-duration="1000">KARAN PRODUCT</h1>
                    <ul class="list-group list-group-horizontal justify-content-center align-items-center mb-4" data-aos="zoom-in-up" data-aos-duration="1000">
                        <Link to='https://www.facebook.com/people/Karan-Product/61550605825809/?mibextid=ZbWKwL' target='_blank'><li class="list-group-item bg-transparent border-0 text-white fs-4 py-0"> <FaFacebookSquare /> </li></Link>
                        <Link to='https://www.instagram.com/karanproduct_alg/?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D' target='_blank'><li class="list-group-item bg-transparent border-0 text-white fs-4 py-0"> <FaInstagram /> </li></Link>
                        <Link to='https://www.youtube.com/@karanproduct5902'><li class="list-group-item bg-transparent border-0 text-white fs-4 py-0"> <FaYoutube /> </li></Link>
                    </ul>
                    <div className="connect d-flex align-items-center gap-5 justify-content-center mb-5" data-aos="zoom-in-up" data-aos-duration="1000">
                    <Link onClick={() => window.location = 'tel: +916395746044'}><p className='btn call'><FaPhoneAlt/> Call Now</p></Link>
                    <Link onClick={() => window.location = 'mailto: karanproductalg@gmail.com'}><p className='btn call'><FaLink/> Connect Now</p></Link>
                    </div>
                </div>
                <p className='text-white text-center mt-3'>Copyright © 2023-2024 Karan Product. All rights reserved</p>
            </footer>
        </>
    )
}

export default Footer