import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaHourglassEnd } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { useRef } from 'react';
import emailjs from '@emailjs/browser';


const Contact = () => {

    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_w0qrju9', 'template_rk3bk08', form.current, '3xfCmk3hPn6PouzdX')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    };

    return (
        <>
            <section className='sec-cont-1'>

            </section>

            <section className='sec-cont-2 my-5'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h3 className='mb-3'>Connect With Us....</h3>
                            <form ref={form} onSubmit={sendEmail}>
                                <input type="text" class="form-control border-0 border-bottom mb-3" id="fullname"
                                    placeholder="Full-Name" name='user_name' required />
                                <input type="email" class="form-control border-0 border-bottom mb-3" id="email"
                                    placeholder="E-mail" name='user_email' required />
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="text" class="form-control border-0 border-bottom mb-3" id="address"
                                            placeholder="Address" name='address' required />
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control border-0 border-bottom mb-3" id="City"
                                            placeholder="City" name='city' required />
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control border-0 border-bottom mb-3" id="state"
                                            placeholder="State" name='state' required />
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control border-0 border-bottom mb-3" id="phone"
                                            placeholder="Phone" name='phone' maxlength="10"
                                            pattern="[0-9]{10}" required />
                                    </div>
                                </div>
                                <div class="form-floating">
                                    <textarea class="form-control border-0 border-bottom mb-3" placeholder="Leave a comment here"
                                        id="Message" name='message' style={{ "height": "100px" }} required></textarea>
                                    <label for="floatingTextarea2">Message..</label>
                                </div>
                                <div class="modal-footer border-0">
                                    <button type="submit" class="btn call">Send message</button>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-6 my-3">
                            <p class="c-blue ls-4 fw-bold">CONTACT</p>
                            <h2 class="c-yellow pb-4">GET IN TOUCH</h2>
                            <div class="row">
                                <div class="col-sm-6 my-3">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-4"><FaPhoneAlt className='fs-2 main-color' /></div>
                                            <div class="col-lg-8">
                                                <h3 class="fw-bold">Phone</h3>
                                                <h6><a href="tel:+91639 574 6044" class=" text-dark fw-bold">Harsh Saraswat:- +91639 574 6044</a>
                                                </h6>
                                                <h6><a href="tel:+91807 7178566" class=" text-dark fw-bold">Brajesh Saraswat:- +91807 717 8566</a>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 my-3">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-4"><FaEnvelope className='fs-2 main-color' /></div>
                                            <div class="col-lg-8">
                                                <h3 class="fw-bold">Email</h3>
                                                <h6><a href="mailto:karanproductalg@gmail.com"
                                                    class="text-dark">karanproductalg@gmail.com</a></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 my-3">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-4"><FaLocationDot className='fs-2 main-color' /></div>
                                            <div class="col-lg-8">
                                                <h3 class="fw-bold">Location</h3>
                                                <p>ADA Colony, Infront of Qr. No. 446, Pala Road Aligarh- 202001 (U.P)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 my-3">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-4"><FaHourglassEnd className='fs-2 main-color' /></div>
                                            <div class="col-lg-8">
                                                <h3 class="fw-bold">Work Hours</h3>
                                                <p>Mon-Sat: 09:00 AM - 5:00 PM</p>
                                                <p>Sun: Closed</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact