import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import { FaCentos } from "react-icons/fa";
import G from '../images/Murti/G.jpg';
import K from '../images/Murti/K.jpg';
import M1 from '../images/Murti/Balaji.png';
import M2 from '../images/Murti/Pic2.png';
import M3 from '../images/Murti/KK.png';
import M4 from '../images/Murti/Ramji.png';
import P1 from '../images/Smurti/1.png';
import P2 from '../images/Smurti/2.png';
import P3 from '../images/Smurti/3.png';
import P4 from '../images/Smurti/4.png';
import P5 from '../images/Smurti/5.png';
import P6 from '../images/Smurti/6.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { FreeMode, Navigation } from 'swiper/modules';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Logo from '../images/Wa.png';
import Backtotop from '../Components/Backtotop';
import Iframe from 'react-iframe';
import Modal from '../Components/Modal';


const Home = () => {


    return (
        <>
            <section className="sec-home-1 mb-5 rounded-5">
                <div className="container">
                </div>
            </section>

            <section className='sec-home-2 my-5'>
                <div className="container w-50 text-center p-3 bg-center rounded-4" data-aos="fade-left" data-aos-duration="1000">
                    <h3 className='mb-3 fw-bold'><FaCentos /> About Us</h3>
                    <p>Karan product offers historical, contemporary, ethnic, & distinctive brass crafts.
                        We provide elegant and fashionable gifts, pooja items, home accents, wall hangings,
                        lamps, figurines of animals, and other items.</p>
                    <button className='btn call' data-bs-toggle="modal" data-bs-target="#exampleModal">Get a Call with us</button>
                </div>
            </section>

            <section className="sec-home-3 my-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 my-4" data-aos="fade-right" data-aos-duration="1000">
                            <img src={G} alt="" className='w-100 rounded-5 ' />
                        </div>
                        <div className="col-lg-7 my-4" data-aos="fade-left" data-aos-duration="1000">
                            <h5 className='jt'>With Karan Product, you can purchase an antique assortment of Hindu god sculptures and idols in India for affordable prices.
                                Only at Karan Product Online Shop can you get a large variety and exposure of Hindu god idols made of metal alloys like bronze and brass online.
                            </h5>
                            <div className="row align-items-center">
                                <div className="col-lg-3 col-md-6 my-3" data-aos="fade-left" data-aos-duration="1200">
                                    <img src={M1} alt="" className='w-100 border border-dark rounded-5 p-2' />
                                </div>
                                <div className="col-lg-3 col-md-6 my-3" data-aos="fade-left" data-aos-duration="1300">
                                    <img src={M2} alt="" className='w-100 border border-dark rounded-5 p-2' />
                                </div>
                                <div className="col-lg-3 col-md-6 my-3" data-aos="fade-left" data-aos-duration="1400">
                                    <img src={M3} alt="" className='w-100 border border-dark rounded-5 p-2' />
                                </div>
                                <div className="col-lg-3 col-md-6 my-3" data-aos="fade-left" data-aos-duration="1500">
                                    <img src={M4} alt="" className='w-100 border border-dark rounded-5 p-2' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='sec-home-5 mb-5'>
                <div className="container text-center border-top border-bottom border-dark">
                    <h2 className='main-color fw-bold my-4' data-aos="zoom-in" data-aos-duration="1000">KARAN PRODUCT</h2>
                    <div className="bg-center p-3 mb-4 rounded-4" data-aos="zoom-in-up" data-aos-duration="1000">
                        <h5 className='mb-4'>With its genuine puja gods, Karan product satisfies your desire for a traditional worship experience. Offer your honest prayers to the Gods and Goddesses using our lucky brass crafts from our Worship Essentials.</h5>
                        <h5>The Indian brass statues will fill your home with good energy and make you and your family feel comfortable. You may create a good atmosphere by using the Idol. </h5>
                    </div>
                    <h3>Small Size Statues</h3>
                    <Swiper
                        navigation={true}
                        slidesPerView={3}
                        spaceBetween={30}
                        freeMode={true}
                        loop={true}
                        breakpoints={{
                            300: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            400: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                        modules={[FreeMode, Navigation]}
                        className="Services mb-5"
                    >
                        <SwiperSlide>
                            <div class="card border-0 bg-transparent">
                                <img src={P1} class="card-img-top w-100 rounded-5" alt="Slider-1" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div class="card border-0 bg-transparent">
                                <img src={P2} class="card-img-top  w-100 rounded-5" alt="Slider-1" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div class="card border-0 bg-transparent">
                                <img src={P3} class="card-img-top  rounded-5" alt="Slider-1" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div class="card border-0 bg-transparent">
                                <img src={P4} class="card-img-top  w-100 rounded-5" alt="Slider-1" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div class="card border-0 bg-transparent">
                                <img src={P5} class="card-img-top  w-100 rounded-5" alt="Slider-1" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div class="card border-0 bg-transparent">
                                <img src={P6} class="card-img-top  w-100 rounded-5" alt="Slider-1" />
                            </div>
                        </SwiperSlide>
                    </Swiper>

                </div>
            </section>

            <section className="sec-home-4 my-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 my-3" data-aos="fade-right" data-aos-duration="1000">
                            <img src={K} alt="" className='w-100 rounded-5' />
                        </div>
                        <div className="col-lg-6 my-3">
                            <h4 className='main-color fw-bold mb-3' data-aos="fade-left" data-aos-duration="1200">Brass items have Spiritual Significance images</h4>
                            <p className='jt mb-3' data-aos="fade-left" data-aos-duration="1400">According to the Vedic literature, brass metal has been utilized to encourage positivity and abundance in life. Using it in prayers may help to awaken spiritual consciousness as it is made of zinc and copper. Each of these three substances is thought to be holy and is a part of the Panch Tattvas.</p>
                            <p className='jt mb-3' data-aos="fade-left" data-aos-duration="1600">Although ancient peoples used copper extensively, the planet Venus has a spiritual connection to the element zinc. Brass is utilized in religious ceremonies because Venus is supposed to influence certain personality attributes including creativity, harmony, and charisma.</p>
                            <p className='jt mb-3' data-aos="fade-left" data-aos-duration="1800">Additionally, using objects made of metals like iron, aluminium, and stainless steel during spiritual devotion is forbidden by the scriptures. Because they are unable to absorb the force of sound waves (prayer), such metals are unable to digest spiritual benefits.</p>
                            <p className='jt mb-3' data-aos="fade-left" data-aos-duration="2000">Precious metals like brass, silver, or gold are used in Hindu culture, Pooja, and other spiritual occasions. Brass artefacts have been used in several contexts, locales, and everyday activities throughout a wide range of traditions.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sec-home-6 mt-5">
                <Iframe url="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3527.177575648021!2d78.07819099999999!3d27.865814999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDUxJzU2LjkiTiA3OMKwMDQnNDEuNSJF!5e0!3m2!1sen!2sin!4v1695480071967!5m2!1sen!2sin"
                    width="100%"
                    height="320px"
                    display="block"
                    position="relative"
                    loading='lazy'
                />
            </section>

            <Backtotop />
            <FloatingWhatsApp
                phoneNumber="916395746044"
                accountName="Karan Product"
                allowEsc
                allowClickAway
                notification
                statusMessage='Last seen minute a ago'
                avatar={Logo}
                notificationSound
            />

            {/* Modal */}
            <Modal />
            {/* Modal-End */}
        </>
    )
}

export default Home;