import React from 'react'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
const Modal = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_w0qrju9', 'template_rk3bk08', form.current, '3xfCmk3hPn6PouzdX')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    };
    return (
        <>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Get Connected...</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form ref={form} onSubmit={sendEmail}>
                                <input type="text" class="form-control border-0 border-bottom mb-3" id="fullname"
                                    placeholder="Full-Name" name='user_name' required />
                                <input type="email" class="form-control border-0 border-bottom mb-3" id="email"
                                    placeholder="E-mail" name='user_email' required />
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="text" class="form-control border-0 border-bottom mb-3" id="address"
                                            placeholder="Address" name='address' required />
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control border-0 border-bottom mb-3" id="City"
                                            placeholder="City" name='city' required />
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control border-0 border-bottom mb-3" id="state"
                                            placeholder="State" name='state' required />
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control border-0 border-bottom mb-3" id="phone"
                                            placeholder="Phone" name='phone' maxlength="10"
                                            pattern="[0-9]{10}" required />
                                    </div>
                                </div>
                                <div class="form-floating">
                                    <textarea class="form-control border-0 border-bottom" placeholder="Leave a comment here"
                                        id="Message" name='message' style={{ "height": "100px" }} required></textarea>
                                    <label for="floatingTextarea2">Message..</label>
                                </div>
                                <div class="modal-footer border-0">
                                    <button type="submit" class="btn call">Send message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal;