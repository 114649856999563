import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import Logo from '../images/Logo.png';
import { useEffect } from "react";
import { FaHome } from "react-icons/fa";
import { RiContactsFill } from "react-icons/ri";
import { NavLink, Link } from 'react-router-dom';

const Head = () => {

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const isSticky = (e) => {
        const header = document.querySelector('.header-section');
        const scrollTop = window.scrollY;
        scrollTop >= 100 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };


    return (
        <>
            <header className='header-section position-fixed w-100'>
                <nav class="navbar navbar-expand-lg bg-transparent">
                    <div class="container-fluid">
                        <Link class="navbar-brand" to='/'><img src={Logo} alt="" className='w-50'/></Link>
                        <button class="navbar-toggler border-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav m-auto mb-2 mb-lg-0 align-items-center">
                                <li class="nav-item pe-4">
                                    <NavLink class="nav-link" aria-current="page" to='/'><FaHome/> HOME</NavLink>
                                </li>
                                <li class="nav-item">
                                    <NavLink class="nav-link" to='/contact'><RiContactsFill/> CONTACT</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Head;