import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from './pages/Home';
import Contact from './pages/Contact';
import Head from './Components/Head';
import Footer from './Components/Footer';
function App() {
  return (
    <BrowserRouter>
      <Head />
      <Routes>
        <Route>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />}></Route>
          <Route path='/*' element={<Navigate to='/' />}></Route>
        </Route>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
